import { useRef, useState, useEffect } from 'react';
import { Formik } from 'formik';
import ReactSelect from 'react-select';
import Spinner from '../../atoms/Spinner/Spinner';
import { FormFilter } from './StyledForm';
import InitialValues from './InitialValues';
import FilterModel from '../../molecules/Filter/FilterModel';
import StyledButton, { ButtonBack } from '../../atoms/Button/StyledButton';

export interface IData {
  arrayDgts: string[],
  arrayDts: string[],
  arrayOds: string[],
  arrayCategories: string[],
  arrayNifs: string[],
  arrayFirstNames: string[],
  arrayLastNames: string[],
  arrayDgtsDtsOds: any,
  arrayDtsOds: any,
  arrayGroups: string[],
  arrayYears: number[],
  arrayStatuses: string[],
  arrayIds?: string[],
  arrayPlanTypes?: string[],
  arrayTicketTypes?: string[],
  arrayTicketStatuses?: string[],
  arrayPlans?: string[],
}

export interface IDataFilter {
  dataFilters: IData | { dropdownFilter: IData },
  filter: FilterModel,
  setFilter: (filter: FilterModel) => void;
  type: string;
  grouping?: string;
  fetching?: boolean;
  inputsToShow?: string[];
  autoFilter?: number;
  className?: string;
  resetFilter?: boolean;
  dateRange?: boolean;
  dateRangeStartMin?: string;
  dateRangeEndMax?: string;
}

export default function FilterForm({
  dataFilters,
  filter,
  setFilter,
  type,
  grouping,
  fetching,
  inputsToShow,
  autoFilter,
  className,
  resetFilter,
  dateRange,
  dateRangeStartMin,
  dateRangeEndMax,
}: IDataFilter) {
  const { initialValues } = InitialValues();
  const idRef = useRef<any>(null);
  const firstNameRef = useRef<any>(null);
  const lastNameRef = useRef<any>(null);
  const categoryRef = useRef<any>(null);
  const dgtRef = useRef<any>(null);
  const dtRef = useRef<any>(null);
  const odRef = useRef<any>(null);
  const nifRef = useRef<any>(null);
  const groupRef = useRef<any>(null);
  const statusRef = useRef<any>(null);
  const planTypeRef = useRef<any>(null);
  const ticketTypeRef = useRef<any>(null);
  const ticketStatusesRef = useRef<any>(null);
  const [options, setOptions] = useState<any>(initialValues);
  const initialOptions: FilterModel = {
    dateRange: filter.dateRange,
    dateRangeStartMin: undefined,
    dateRangeEndMax: undefined,
    category: undefined,
    campaignTypeId: undefined,
    rappelTypeId: undefined,
    conventionTypeId: undefined,
    dgt: undefined,
    dt: undefined,
    od: undefined,
    nif: undefined,
    firstName: undefined,
    lastName: undefined,
    group: undefined,
    status: '',
    grouping,
    type,
    id: undefined,
    selectedScope: undefined,
    planType: undefined,
    ticketType: undefined,
    ticketStatus: undefined,
    ...filter,
  };

  const formattedData:any = {};
  let hashDataFilters: any = {};

  if (dataFilters) {
    if ('dropdownFilter' in dataFilters) {
      hashDataFilters = {
        arrayIds: dataFilters.dropdownFilter.arrayIds,
        arrayDgts: dataFilters.dropdownFilter.arrayDgts,
        arrayDts: dataFilters.dropdownFilter.arrayDts,
        arrayOds: dataFilters.dropdownFilter.arrayOds,
        arrayCategories: dataFilters.dropdownFilter.arrayCategories,
        arrayFirstNames: dataFilters.dropdownFilter.arrayFirstNames,
        arrayLastNames: dataFilters.dropdownFilter.arrayLastNames,
        arrayNifs: dataFilters.dropdownFilter.arrayNifs,
        arrayDgtsDtsOds: dataFilters.dropdownFilter.arrayDgtsDtsOds,
        arrayDtsOds: dataFilters.dropdownFilter.arrayDtsOds,
        arrayGroups: dataFilters.dropdownFilter.arrayGroups,
        arrayYears: dataFilters.dropdownFilter.arrayYears,
        arrayStatuses: dataFilters.dropdownFilter.arrayStatuses,
        arrayPlanTypes: dataFilters.dropdownFilter.arrayPlanTypes,
        arrayTicketTypes: dataFilters.dropdownFilter.arrayTicketTypes,
        arrayTicketStatuses: dataFilters.dropdownFilter.arrayTicketStatuses,
        arrayPlans: dataFilters.dropdownFilter.arrayPlans,
      };
    } else {
      hashDataFilters = {
        arrayIds: dataFilters.arrayIds,
        arrayDgts: dataFilters.arrayDgts,
        arrayDts: dataFilters.arrayDts,
        arrayOds: dataFilters.arrayOds,
        arrayCategories: dataFilters.arrayCategories,
        arrayFirstNames: dataFilters.arrayFirstNames,
        arrayLastNames: dataFilters.arrayLastNames,
        arrayNifs: dataFilters.arrayNifs,
        arrayDgtsDtsOds: dataFilters.arrayDgtsDtsOds,
        arrayDtsOds: dataFilters.arrayDtsOds,
        arrayGroups: dataFilters.arrayGroups,
        arrayYears: dataFilters.arrayYears,
        arrayStatuses: dataFilters.arrayStatuses,
        arrayPlanTypes: dataFilters.arrayPlanTypes,
        arrayTicketTypes: dataFilters.arrayTicketTypes,
        arrayTicketStatuses: dataFilters.arrayTicketStatuses,
        arrayPlans: dataFilters.arrayPlans,
      };
    }
  }

  Object.keys(hashDataFilters).map((k) => {
    if (hashDataFilters[k]?.length) {
      const arrayData = hashDataFilters[k].map(
        (e:string) => ({ label: e, value: e }),
      );
      formattedData[k] = arrayData;
    }
    return null;
  }).filter((b) => b !== null);

  const [isClear, setIsClear] = useState<any>(false);
  const [internalFilter, setInternalFilter] = useState<FilterModel>(new FilterModel(filter));

  const handleSubmit = (values: any, actions: any) => {
    setFilter(internalFilter);
    setTimeout(() => {
      actions.setSubmitting(false);
    }, 900);
  };

  const handleInputChange = (event: any, name: string) => {
    if (name === 'startDate' || name === 'endDate') {
      setInternalFilter({
        ...internalFilter,
        [name]: event?.target?.value,
      });
    } else {
      setInternalFilter({
        ...internalFilter,
        [name]: event?.value,
      });
    }
  };

  const clearFilter = (event: any) => {
    event.preventDefault();
    setInternalFilter(new FilterModel(options));
    setFilter(new FilterModel(options));
    setOptions(formattedData);
    setIsClear(fetching);
    idRef.current?.clearValue();
    firstNameRef.current?.clearValue();
    lastNameRef.current?.clearValue();
    categoryRef.current?.clearValue();
    dgtRef.current?.clearValue();
    dtRef.current?.clearValue();
    odRef.current?.clearValue();
    nifRef.current?.clearValue();
    groupRef.current?.clearValue();
    statusRef.current?.clearValue();
    planTypeRef.current?.clearValue();
    ticketTypeRef.current?.clearValue();
    ticketStatusesRef.current?.clearValue();
  };

  useEffect(() => {
    setOptions(formattedData);
  }, []);

  useEffect(() => {
    setInternalFilter(filter);
  }, [filter]);

  useEffect(() => {
    if (!isClear) {
      setInternalFilter(new FilterModel(initialOptions));
    }
  }, [!isClear]);

  useEffect(() => {
    if (resetFilter) {
      setInternalFilter(new FilterModel(initialOptions));
    }
  }, [resetFilter]);

  useEffect(() => {
    if (autoFilter === 0) {
      if (
        internalFilter.dgt !== undefined
        || internalFilter.dt !== undefined
        || internalFilter.od !== undefined
        || internalFilter.nif !== undefined
        || internalFilter.status !== undefined
        || internalFilter.planType !== undefined
        || internalFilter.ticketType !== undefined
        || internalFilter.ticketStatus !== undefined
        || internalFilter.startDate !== undefined
        || internalFilter.endDate !== undefined
      ) {
        setFilter(internalFilter);
      }
    }
  }, [
    autoFilter === 0,
    internalFilter.dgt,
    internalFilter.dt,
    internalFilter.od,
    internalFilter.nif,
    internalFilter.status,
    internalFilter.planType,
    internalFilter.ticketType,
    internalFilter.ticketStatus,
    internalFilter.startDate,
    internalFilter.endDate,
  ]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({ isSubmitting }) => (
        <FormFilter className={className}>
          <div className={`${dateRange ? 'groupFields dateRange' : 'groupFields'}`}>
            {((inputsToShow?.length === 0 || inputsToShow?.includes('firstName')) && hashDataFilters?.arrayFirstNames) ? (
              <div className="field">
                <label htmlFor="state">Nombre</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-first-name"
                  defaultValue={
                    options.arrayFirstNames
                  }
                  isSearchable
                  isClearable
                  name="firstName"
                  ref={firstNameRef}
                  options={options.arrayFirstNames}
                  onChange={(e: any) => handleInputChange(e, 'firstName')}
                />
              </div>
            ) : ''}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('lastName'))) && hashDataFilters?.arrayLastNames ? (
              <div className="field">
                <label htmlFor="state">Apellidos</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-last-name"
                  defaultValue={
                    options.arrayLastNames
                  }
                  isSearchable
                  isClearable
                  name="lastName"
                  ref={lastNameRef}
                  options={options.arrayLastNames}
                  onChange={(e: any) => handleInputChange(e, 'lastName')}
                />
              </div>
            ) : ''}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('nif'))) && hashDataFilters?.arrayNifs ? (
              <div className={`field ${autoFilter === 0 ? 'last-position' : ''}`}>
                <label htmlFor="state">NIF</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-nif"
                  defaultValue={autoFilter === 0
                    ? hashDataFilters.arrayNifs?.filter((e :any) => e.value === filter.nif)[0]
                    : options.arrayNifs}
                  isSearchable
                  isClearable
                  name="nif"
                  ref={nifRef}
                  options={
                    autoFilter === 0 ? hashDataFilters.arrayNifs : options.arrayNifs
                  }
                  onChange={(event: any) => {
                    handleInputChange(event, 'nif');
                  }}
                />
              </div>
            ) : ''}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('category'))) && hashDataFilters?.arrayCategories ? (
              <div className="field">
                <label htmlFor="state">Categoría</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-category"
                  defaultValue={
                    options.arrayCategories
                  }
                  isSearchable
                  isClearable
                  name="category"
                  ref={categoryRef}
                  options={hashDataFilters.arrayCategories}
                  onChange={(e: any) => handleInputChange(e, 'category')}
                />
              </div>
            ) : ''}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('dgt'))) && hashDataFilters?.arrayDgts ? (
              <div className="field">
                <label htmlFor="state">DGT</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-dgt"
                  defaultValue={
                    filter.dgt || autoFilter === 0
                      ? hashDataFilters.arrayDgts?.find((e :any) => e.value === filter.dgt)
                      : options.arrayDgts
                  }
                  isSearchable
                  isClearable
                  name="dgt"
                  ref={dgtRef}
                  options={hashDataFilters.arrayDgts}
                  onChange={(e: any) => handleInputChange(e, 'dgt')}
                />
              </div>
            ) : ''}
            {((inputsToShow?.length === 0 || inputsToShow?.includes('dt'))) && hashDataFilters?.arrayDts ? (
              <div className="field">
                <label htmlFor="state">DT</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-dt"
                  defaultValue={filter.dt || autoFilter === 0
                    ? hashDataFilters.arrayDts?.filter((e :any) => e.value === filter.dt)[0]
                    : options.arrayDts}
                  isSearchable
                  isClearable
                  name="dt"
                  ref={dtRef}
                  options={internalFilter.dgt ? hashDataFilters.arrayDgtsDtsOds[`DGT${internalFilter.dgt}`].DTS : hashDataFilters.arrayDts}
                  onChange={(e: any) => handleInputChange(e, 'dt')}
                />
              </div>
            ) : ''}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('od'))) && hashDataFilters?.arrayOds ? (
              <div className="field">
                <label htmlFor="state">OD</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-od"
                  defaultValue={filter.od || autoFilter === 0
                    ? hashDataFilters.arrayOds?.filter((e :any) => e.value === filter.od)[0]
                    : options.arrayOds}
                  isSearchable
                  isClearable
                  name="od"
                  ref={odRef}
                  options={internalFilter.dt ? hashDataFilters.arrayDtsOds[`DT${internalFilter.dt}`] : internalFilter.dgt ? hashDataFilters.arrayDgtsDtsOds[`DGT${internalFilter.dgt}`].ODS : hashDataFilters.arrayOds}
                  onChange={(e: any) => handleInputChange(e, 'od')}
                />
              </div>
            ) : ('')}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('status'))) && hashDataFilters?.arrayStatuses ? (
              <div className="field">
                <label htmlFor="state">Estado</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-status"
                  defaultValue={hashDataFilters.arrayStatuses
                    ?.filter((e :any) => e.value === filter.status)[0]}
                  isSearchable
                  isClearable
                  name="status"
                  ref={statusRef}
                  options={type === 'Convention' ? hashDataFilters.arrayStatuses.slice(0, 2) : hashDataFilters.arrayStatuses}
                  onChange={(e: any) => handleInputChange(e, 'status')}
                />
              </div>
            ) : ('')}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('ticketStatus'))) && hashDataFilters?.arrayTicketStatuses ? (
              <div className="field">
                <label htmlFor="state">Estado de ticket</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-ticket-status"
                  defaultValue={hashDataFilters.arrayTicketStatuses
                    ?.filter((e :any) => e.value === filter.ticketStatus)[0]}
                  isSearchable
                  isClearable
                  name="ticketStatus"
                  ref={ticketStatusesRef}
                  options={hashDataFilters.arrayTicketStatuses}
                  onChange={(e: any) => handleInputChange(e, 'ticketStatus')}
                />
              </div>
            ) : ('')}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('planType'))) && hashDataFilters?.arrayPlanTypes ? (
              <div className="field">
                <label htmlFor="state">Tipo de plan</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-plan-type"
                  defaultValue={hashDataFilters.arrayPlanTypes
                    ?.filter((e :any) => e.value === filter.planType)[0]}
                  isSearchable
                  isClearable
                  name="planType"
                  ref={planTypeRef}
                  options={hashDataFilters.arrayPlanTypes}
                  onChange={(e: any) => handleInputChange(e, 'planType')}
                />
              </div>
            ) : ('')}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('ticketType'))) && hashDataFilters?.arrayTicketTypes ? (
              <div className="field">
                <label htmlFor="state">Tipo de ticket</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-ticket-type"
                  defaultValue={hashDataFilters.arrayTicketTypes
                    ?.filter((e :any) => e.value === filter.ticketType)[0]}
                  isSearchable
                  isClearable
                  name="ticketType"
                  ref={ticketTypeRef}
                  options={hashDataFilters.arrayTicketTypes}
                  onChange={(e: any) => handleInputChange(e, 'ticketType')}
                />
              </div>
            ) : ('')}

            {((inputsToShow?.length === 0 || inputsToShow?.includes('plans'))) && hashDataFilters?.arrayPlans ? (
              <div className="field">
                <label htmlFor="state">Tipo de ticket</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-ticket-type"
                  defaultValue={hashDataFilters.arrayPlans
                    ?.filter((e :any) => e.value === filter.ticketType)[0]}
                  isSearchable
                  isClearable
                  name="plans"
                  ref={ticketTypeRef}
                  options={hashDataFilters.arrayPlans}
                  onChange={(e: any) => handleInputChange(e, 'plans')}
                />
              </div>
            ) : ('')}

            {type === 'Convention' && hashDataFilters.arrayGroups ? (
              <div className="field">
                <label htmlFor="state">Grupos</label>
                <ReactSelect
                  className="basic-single"
                  classNamePrefix="select-group"
                  defaultValue={
                    options.arrayGroups
                  }
                  isSearchable
                  isClearable
                  name="group"
                  ref={groupRef}
                  options={hashDataFilters.arrayGroups}
                  onChange={(e: any) => handleInputChange(e, 'group')}
                />
              </div>
            ) : ''}

            {dateRange && (
              <div className="rangeDate">
                <div className="field">
                  <label htmlFor="state">Fecha inicio</label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(e: any) => handleInputChange(e, 'startDate')}
                    min={dateRangeStartMin}
                  />
                </div>
                <div className="field">
                  <label htmlFor="state">Fecha fin</label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={(e: any) => handleInputChange(e, 'endDate')}
                    max={dateRangeEndMax}
                  />
                </div>
              </div>
            )}
          </div>
          {autoFilter !== 0 && (
            <div className="groupCta">
              <ButtonBack className={type} onClick={clearFilter}>
                Borrar Filtros
              </ButtonBack>
              <StyledButton type="submit" className={type} size={160} disabled={isSubmitting}>
                {!isSubmitting ? (
                  'Filtrar'
                ) : (
                  <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
                )}
              </StyledButton>
            </div>
          )}
        </FormFilter>
      )}
    </Formik>
  );
}

FilterForm.defaultProps = {
  fetching: false,
  grouping: '',
  inputsToShow: [],
  autoFilter: -1,
  className: '',
  resetFilter: false,
  dateRange: false,
  dateRangeStartMin: undefined,
  dateRangeEndMax: undefined,
};
