import loginImage from '../assets/images/bglogin.png';

const Theme = {
  colorPrimary: '#D81E05',
  colorSecondary: '#2D373D',
  colorSecondary2: '#F5F6F7',
  colorSecondary3: '#9CB0BC',
  colorSecondary4: '#F5F6F8',
  colorBorder: '#D1D0D0',
  colorTertiary: '#E8EBED',
  colorCampaign: '#D81E05',
  colorCampaignAlternative: '#9D1D13',
  colorRappel: '#045B4E',
  colorRappelDinamizations: '#007B6A',
  colorConvention: '#428EBD',
  colorRanking: '#CEB277',
  colorWhite: '#FFFFFF',
  colorBlack: '#000000',
  colorError: '#c53030',
  colorErrorBg: '#fecaca',
  colorSuccess: '#2F9E44',
  colorSuccessBg: '#C6F6D5',
  colorDisabled: '#9CB0BC',
  fontColor: '#716D6D',
  fontColorSecondary: '#526570',
  fontFamily: 'DM Sans, sans-serif',
  loginImage,
  radius: '8px',
  radiusField: '4px',
  shadow: '0px 2px 4px rgba(0, 0, 0, 0.25), 2px 0px 4px -1px rgba(0, 0, 0, 0.25)',
  sizeContainer: '1156px',
  sizeContainerXL: '1556px',
  colorGreen: '#4ade80',
  colorRed: '#f43f5e',
  colorGray: '#a1a1aa',
};

export default Theme;
