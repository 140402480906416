import { Formik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import ReactSelect from 'react-select';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Theme from '../../../styles/Theme';
import { InputFieldForm } from '../../atoms/Field/StyledField';
import Button from '../../atoms/Button/Button';
import Spinner from '../../atoms/Spinner/Spinner';
import Container from '../../atoms/Container/Container';
import Typography from '../../atoms/Typography/Typography';
import FileUpload from '../../atoms/FileUpload/FileUpload';
import { ButtonBack } from '../../atoms/Button/StyledButton';
import Error, { Success } from '../../atoms/Error/StyledError';
import useFormTicketManager from '../../../hooks/useFormTicketManager';
import { FormTicket } from '../../organisms/Login/StyledForm';
import { FormSkeletonSelect } from '../../organisms/FilterForm/StyledForm';
import useYear from '../../../hooks/useYear';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_PLAN_LIST_CREATE_TICKET_GQL from '../../../graphql/queries/getPlanListCreateTicketGql';

export default function TicketManager() {
  const { planId } = useParams();
  const { year } = useYear();
  const graphQLClient = createGraphQLClient();
  const [selectedUserId, setSelectedUserId] = useState<any>(undefined);
  const [selectedYear, setSelectedYear] = useState<any>(year);
  const [selectedType, setSelectedType] = useState<any>(undefined);
  const {
    navigate,
    internalValue,
    setInternalValue,
    options,
    handleSubmit,
    initialValuesTicket,
    validationSchemaTicket,
    hashTicket,
    nifRef,
    ticketTypeRef,
    planIdRef,
    subjectValue,
    bodyValue,
    setSubjectValue,
    setBodyValue,
    files,
    setFiles,
    handleInputChange,
    errorMessage,
    submitError,
    submitSuccess,
    stripHtml,
  } = useFormTicketManager();
  const planTypeRef = useRef<any>(null);
  const selectedYearRef = useRef<any>(year);

  const {
    data: dataPlans,
    refetch: refetchDataPlans,
    isFetching: isFetchingDataPlans,
  } = useQuery(
    ['planListCreateTicket', year],
    async () => (graphQLClient
      && graphQLClient.request(
        GET_PLAN_LIST_CREATE_TICKET_GQL,
        { year: selectedYear, userId: selectedUserId, type: selectedType },
      )),
    {
      staleTime: Infinity,
    },
  );

  let arrayPlans: any = {};
  arrayPlans = { arrayPlans: dataPlans?.planListCreateTicket?.arrayPlans };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  if (planId && arrayPlans.arrayPlans && !internalValue.planId) {
    const planSelected = arrayPlans.arrayPlans
      ?.filter((e :any) => e.value.toString() === planId)[0];
    if (planSelected?.value) {
      handleInputChange(planSelected, 'planId');
      setInternalValue({
        ...internalValue,
        planId: planSelected?.value,
      });
    }
  }

  useEffect(() => {
    setInternalValue({
      ...internalValue,
      files,
    });
  }, [files]);

  useEffect(() => {
    setSelectedUserId(internalValue.relatedUserId);
  }, [internalValue.relatedUserId]);

  useEffect(() => {
    refetchDataPlans();
  }, [selectedUserId, selectedYear, selectedType]);

  return (
    <Container>
      <ButtonBack onClick={() => navigate('/help')} type="button">Volver</ButtonBack>
      <Typography color={Theme.colorPrimary} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">Creación de incidencia</Typography>
      <div style={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}>
        <Formik
          enableReinitialize
          initialValues={initialValuesTicket}
          validationSchema={validationSchemaTicket}
          onSubmit={() => {}}
        >
          {({ isSubmitting }) => (
            <FormTicket>
              {hashTicket.arrayRelatedUserId ? (
                <div className="field">
                  <label htmlFor="state">Nif Asociado a la incidencia</label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select-relatedUserId"
                    defaultValue={
                      options.arrayRelatedUserId
                    }
                    isSearchable
                    isClearable
                    name="category"
                    ref={nifRef}
                    options={hashTicket.arrayRelatedUserId}
                    onChange={(e: any) => handleInputChange(e, 'relatedUserId')}
                  />
                  {(internalValue?.relatedUserId === undefined
                  && errorMessage?.relatedUserId) && (
                    <Error>{errorMessage?.relatedUserId}</Error>
                  )}
                </div>
              ) : (
                <FormSkeletonSelect />
              )}
              <div style={{ width: '100%', display: 'flex' }}>
                {hashTicket.arrayTicketType ? (
                  <div className="field" style={{ width: '30%', marginRight: '5%' }}>
                    <label htmlFor="state">Tipo de incidencia</label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select-ticket-type"
                      defaultValue={options.arrayTicketType}
                      isSearchable
                      isClearable
                      name="ticketType"
                      ref={ticketTypeRef}
                      options={hashTicket.arrayTicketType}
                      onChange={(e: any) => handleInputChange(e, 'ticketType')}
                    />
                    {(internalValue?.ticketType === undefined
                    && errorMessage?.ticketType) && (
                      <Error>{errorMessage?.ticketType}</Error>
                    )}
                  </div>
                ) : (
                  <div style={{ width: '30%', marginRight: '5%' }}>
                    <FormSkeletonSelect />
                  </div>
                )}
                {hashTicket.arrayYears ? (
                  <div className="field" style={{ width: '30%', marginRight: '5%' }}>
                    <label htmlFor="state">Año</label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select-ticket-type"
                      defaultValue={hashTicket.arrayYears
                        ?.filter((e :any) => e.value.toString() === selectedYear.toString())[0]}
                      isSearchable
                      isClearable={false}
                      name="ticketType"
                      ref={selectedYearRef}
                      options={hashTicket.arrayYears}
                      onChange={(e: any) => setSelectedYear(e?.value)}
                    />
                  </div>
                ) : (
                  <div style={{ width: '30%', marginRight: '5%' }}>
                    <FormSkeletonSelect />
                  </div>
                )}
                {hashTicket.arrayPlanType ? (
                  <div className="field" style={{ width: '30%' }}>
                    <label htmlFor="state">Tipo de plan</label>
                    <ReactSelect
                      className="basic-single"
                      classNamePrefix="select-ticket-type"
                      defaultValue={selectedType}
                      isSearchable
                      isClearable
                      name="ticketType"
                      ref={planTypeRef}
                      options={hashTicket.arrayPlanType}
                      onChange={(e: any) => setSelectedType(e?.value)}
                    />
                  </div>
                ) : (
                  <div style={{ width: '30%' }}>
                    <FormSkeletonSelect />
                  </div>
                )}
              </div>
              {arrayPlans.arrayPlans && !isFetchingDataPlans ? (
                <div className="field">
                  <label htmlFor="state">Plan</label>
                  <ReactSelect
                    className="basic-single"
                    classNamePrefix="select-plan-type"
                    defaultValue={arrayPlans.arrayPlans
                      ?.filter((e :any) => e.value.toString() === planId)[0]}
                    isSearchable
                    isClearable
                    name="planId"
                    ref={planIdRef}
                    options={arrayPlans.arrayPlans}
                    onChange={(e: any) => handleInputChange(e, 'planId')}
                  />
                  {((internalValue?.planId === undefined)
                  && errorMessage?.planId) && (
                    <Error>{errorMessage?.planId}</Error>
                  )}
                </div>
              ) : (
                <FormSkeletonSelect />
              )}
              <div className="field">
                <label htmlFor="state">Asunto</label>
                <InputFieldForm
                  name="subject"
                  type="text"
                  placeholder="Asunto"
                  autoComplete="off"
                  value={subjectValue}
                  onChange={(e: any) => {
                    setSubjectValue(e.target.value);
                    handleInputChange(e, 'subject');
                  }}
                  className={internalValue?.subject === '' || (internalValue?.subject && internalValue?.subject?.length > 144) ? 'error' : ''}
                />
                <span>{`${internalValue?.subject?.length || 0}/144`}</span>
                { errorMessage.subject?.length !== 0 && (
                  <Error>{errorMessage.subject}</Error>
                )}
              </div>
              <div className="field">
                <label htmlFor="state">Mensaje</label>
                <ReactQuill
                  value={bodyValue}
                  placeholder="Mensaje"
                  onChange={(e: any) => {
                    setBodyValue(e);
                    handleInputChange(e, 'body');
                  }}
                  modules={modules}
                  className={stripHtml(internalValue?.body) === '' || (stripHtml(internalValue?.body) && stripHtml(internalValue?.body).length > 2000) ? 'error' : ''}
                />
                <span>{`${stripHtml(internalValue?.body).length || 0}/2000`}</span>
                {errorMessage.body?.length !== 0 && (
                  <Error>{errorMessage.body}</Error>
                )}
              </div>
              <FileUpload
                publicUrls={files}
                setPublicUrls={setFiles}
                maxMbSize={5}
                multipleFiles
              />
              {submitError !== '' && (
                <Error>{submitError}</Error>
              )}
              {submitSuccess !== '' && (
                <Success>{submitSuccess}</Success>
              )}
              <Button
                type="button"
                size={262}
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {!isSubmitting ? (
                  'Enviar'
                ) : (
                  <Spinner background="rgba(200,200,200,0.6)" color="#fff" />
                )}
              </Button>
            </FormTicket>
          )}
        </Formik>
      </div>
    </Container>
  );
}
