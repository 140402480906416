import { useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { NumberFormat } from 'xlsx';
import Theme from '../../../styles/Theme';
import GET_MY_INFO_GQL from '../../../graphql/queries/getCurrentUserInfoGql';
import { Card } from '../../atoms/Card/Card';
import Container from '../../atoms/Container/Container';
import FileUpload from '../../atoms/FileUpload/FileUpload';
import Typography from '../../atoms/Typography/Typography';
import {
  DetailTicketContainer, DetailTicketContainerContent, CancelButton,
  BodyContainer, AcceptButton, ResponseButton, ButtonBack,
} from './StyledTicket';
import { getTokenFromStore } from '../../../store/sessionStore';
import { createGraphQLClient } from '../../../graphql/graphqlClient';
import GET_TICKET_INFO from '../../../graphql/queries/getTicketInfoGql';
import RESPONSE_TICKET_GQL from '../../../graphql/mutations/responseTicketGql';
// import GET_CURRENT_USER_DATA_INFO_GQL from '../../../graphql/queries/getCurrentUserDataInfoGql';

const FileUrl = styled.a`
  font-size: 12px;
`;

export default function TicketDetail() {
  const { ticket } = useParams();
  const graphQLClient = createGraphQLClient();
  const token = getTokenFromStore();
  const auth = `Bearer ${token}`;
  const impersonateId = localStorage.getItem('impersonateUser') ? JSON.parse(localStorage.getItem('impersonateUser') || '').id : null;
  const headers:any = { authorization: auth };
  if (impersonateId) {
    headers['impersonate-id'] = impersonateId;
  }
  const [responseBody, setResponseBody] = useState('');
  const [files, setFiles] = useState([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const user: any = queryClient?.getQueryState(['UserInfo']);
  const userNif = user?.data?.me?.nif;

  const {
    data,
    isFetching,
  } = useQuery(
    ['TicketInfo', { ticketId: ticket }],
    async () => (graphQLClient && graphQLClient.request(GET_TICKET_INFO, { ticketId: ticket })),
    {
      enabled: true,
    },
  );

  const {
    data: dataCurrentUserInfo,
  } = useQuery(
    ['ConventionCurrentUserInfo'],
    async () => (graphQLClient && graphQLClient.request(
      GET_MY_INFO_GQL,
    )),
    {
      staleTime: Infinity,
    },
  );

  const ticketInfo = data?.ticketInfo;
  const lastResponse = data?.ticketInfo?.ticketResponses?.[0];

  const responseTicket = (values: any) => axios.post(`${process.env.REACT_APP_GRAPHQL_ENDPOINT}`, {
    query: RESPONSE_TICKET_GQL,
    variables: {
      ticketId: values.ticketId,
      body: values.body,
      action: values.action,
      files: values.files,
    },
  }, {
    headers,
  }).then(() => {
    window.location.reload();
  });

  const { mutate } = useMutation(responseTicket);

  const handleResponseTicket = (action: any) => {
    mutate({
      ticketId: ticket,
      body: responseBody,
      files,
      action,
    } as any);
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  return (
    <DetailTicketContainer>
      {!isFetching && !ticketInfo && (
        <Typography size="14px" margin="0 0 1rem" display="block" align="center">
          No hay datos para este ticket
        </Typography>
      )}
      {!isFetching && ticketInfo && (
        <DetailTicketContainerContent>
          <Container>
            <ButtonBack onClick={() => navigate(-1)} type="button">Volver</ButtonBack>
          </Container>

          { !['Resuelto', 'Cancelado', 'Caducado'].includes(ticketInfo.ticketStatus) && (
            <CancelButton
              type="button"
              onClick={() => handleResponseTicket('cancel')}
            >
              Cancelar ticket
            </CancelButton>
          )}

          <Typography color={Theme.colorCampaign} size="32px" margin="0 0 1rem" display="block" align="center" weight="500">
            Ticket: &nbsp;
            { ticketInfo.internalId }
          </Typography>

          <Card
            key="ticket-detail-data"
            padding="1.5rem"
            alignItems="center"
            width="100%"
            radius="5px"
          >
            <Typography size="16px" margin="0 0 1rem" display="block" align="left">
              <Typography color={Theme.colorCampaign}>Beneficiario:&nbsp;</Typography>
              { ticketInfo.relatedUserName }
                &nbsp;-&nbsp;
              { ticketInfo.relatedUserNif }
              <br />
              <Typography color={Theme.colorCampaign}>Plan / Incentivo:&nbsp;</Typography>
              { ticketInfo.planName }
              {ticketInfo.planType !== 'Genérico' && (` - ${ticketInfo.planType}`)}
              <br />
              <Typography color={Theme.colorCampaign}>Tipo:&nbsp;</Typography>
              { ticketInfo.ticketType }
            </Typography>
          </Card>

          <div style={{ width: '100%' }}>
            <Typography color={Theme.colorCampaign} size="22px" margin="3rem 0 1rem" display="block" align="center" weight="500">
              { ticketInfo.subject }
            </Typography>

            <Typography size="16px" margin="0 0 1rem" display="block" align="left">
              <div style={{ overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: ticketInfo.body }} />
              <br />
              <div>
                { ticketInfo.files && (
                  ticketInfo.files.map((url:any) => (
                    <>
                      <FileUrl href={url} target="_blank">{url.match(/[^/]+$/)[0]}</FileUrl>
                      <br />
                    </>
                  ))
                )}
              </div>
            </Typography>

            <Typography color={Theme.colorCampaign} size="20px" margin="1rem 0 1rem" display="block" align="center" weight="500">
              Tu conversación:
            </Typography>

            { ticketInfo.ticketResponses?.length > 0 ? (
              <>
                <Card
                  key="ticket-detail"
                  padding="1.5rem"
                  alignItems="center"
                  margin="0 0 50px 0"
                >
                  {ticketInfo.ticketResponses.map((response: any, index: NumberFormat) => (
                    <div style={{
                      width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                    }}
                    >
                      <div style={{ width: '80%', marginLeft: ['definitive_response', 'request_info_response'].includes(response.actionType) ? 'inherit' : 'auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography size="12px" display="block" align="left" weight="900">
                            { ['definitive_response', 'request_info_response'].includes(response.actionType) ? 'Gestor' : dataCurrentUserInfo?.me?.firstName }
                          </Typography>
                          <Typography size="12px" display="block" align="right">
                            {response.createdAt}
                          </Typography>
                        </div>
                        <BodyContainer>
                          <Typography size="14px" margin="0 0 1rem" display="block" align="left">
                            <div dangerouslySetInnerHTML={{ __html: response.body }} style={{ overflowX: 'auto', overflowWrap: 'break-word' }} />
                            <div>
                              {response.files.map((url:any) => (
                                <>
                                  <FileUrl href={url} target="_blank">{url.match(/[^/]+$/)[0]}</FileUrl>
                                  <br />
                                </>
                              ))}
                            </div>
                          </Typography>
                        </BodyContainer>
                      </div>

                      {index === 0 && lastResponse?.actionType === 'definitive_response' && !['Resuelto', 'Cancelado', 'Caducado'].includes(ticketInfo.ticketStatus) && (
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
                          <AcceptButton
                            type="button"
                            onClick={() => handleResponseTicket('accept')}
                            style={{ marginLeft: '20px' }}
                          >
                            Aceptar y Finalizar
                          </AcceptButton>
                        </div>
                      )}
                    </div>
                  ))}
                </Card>
                { ticketInfo.ticketResponses[0]?.userNif !== userNif
                  && !['Resuelto', 'Cancelado', 'Caducado'].includes(ticketInfo.ticketStatus) && (
                  <>
                    <div style={{ width: '100%', marginBottom: '1rem' }}>
                      <FileUpload
                        publicUrls={files}
                        setPublicUrls={setFiles}
                        maxMbSize={5}
                        multipleFiles
                      />
                    </div>
                    <div style={{ width: '100%', display: 'block', minHeight: '180px' }}>
                      <div style={{
                        width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '1rem',
                      }}
                      >
                        <ReactQuill
                          value={responseBody}
                          placeholder="Respuesta"
                          onChange={(e: any) => {
                            setResponseBody(e);
                          }}
                          style={{ width: '100%', paddingRight: '160px', marginBottom: '60px' }}
                          modules={modules}
                        />
                        <ResponseButton
                          type="button"
                          onClick={() => handleResponseTicket('ticketCreatorResponse')}
                        >
                          Responder
                        </ResponseButton>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <Typography size="14px" margin="0 0 1rem" display="block" align="center">
                No hay respuestas
              </Typography>
            )}
          </div>
        </DetailTicketContainerContent>
      )}
    </DetailTicketContainer>
  );
}
